import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  en: {
      title1: "“Словацька з нуля”",
      text1: "Підійде для початківців, студентів, які ще не вивчали словацьку. В групі 5-6 учасників. Курс триває 3 місяці, 25 занять по 1,5 год. Вартість 198€.",
      title2: "Курс - А2",
      text2: "Підійде для тих, хто вже має базу зі словацької (пройшов рівень А1 або наш курс “Словацька з нуля”, але ще має теми, які потрібно пройти та невпевнено розмовляє словацькою. В групі 5-6 учасників. Курс триває …… (допишемо)",
      title3: "Розмовний клуб для рівнів В1+",
      text3: "Клуб підходить для тих, хто вже говорить словацькою та знає її на хорошому рівні. Зосереджується на розмовній практиці та поповненні словникового запасу. Вартість одного заняття - 1,5 год - 7€.",
      title4: "Індивідуальні заняття",
      text4: "Підходить для будь-якого рівня, від початківців до С1. Одне заняття триває 60 хвилин. Вартість 10€.",
      title5: "Парні заняття.",
      text5: "Підходить для друзів, партнерів або незнайомих студентів, які хочуть покращити свою словацьку в парі. Заняття триває 60 хв. Вартість за двох студентів 15€ (один студент - 7.5€)",
      appl:'APPLY'
  },
  ua: {
    title1: "“Словацька з нуля”",
    text1: "Підійде для початківців, студентів, які ще не вивчали словацьку. В групі 5-6 учасників. Курс триває 3 місяці, 25 занять по 1,5 год. Вартість 198€.",
    title2: "Курс - А2",
    text2: "Підійде для тих, хто вже має базу зі словацької (пройшов рівень А1 або наш курс “Словацька з нуля”, але ще має теми, які потрібно пройти та невпевнено розмовляє словацькою. В групі 5-6 учасників. Курс триває …… (допишемо)",
    title3: "Розмовний клуб для рівнів В1+",
    text3: "Клуб підходить для тих, хто вже говорить словацькою та знає її на хорошому рівні. Зосереджується на розмовній практиці та поповненні словникового запасу. Вартість одного заняття - 1,5 год - 7€.",
    title4: "Індивідуальні заняття",
    text4: "Підходить для будь-якого рівня, від початківців до С1. Одне заняття триває 60 хвилин. Вартість 10€.",
    title5: "Парні заняття.",
    text5: "Підходить для друзів, партнерів або незнайомих студентів, які хочуть покращити свою словацьку в парі. Заняття триває 60 хв. Вартість за двох студентів 15€ (один студент - 7.5€)",
      appl:'ЗАПИСАТИСЬ'
  },
  sk: {
    title1: "“Словацька з нуля”",
    text1: "Підійде для початківців, студентів, які ще не вивчали словацьку. В групі 5-6 учасників. Курс триває 3 місяці, 25 занять по 1,5 год. Вартість 198€.",
    title2: "Курс - А2",
    text2: "Підійде для тих, хто вже має базу зі словацької (пройшов рівень А1 або наш курс “Словацька з нуля”, але ще має теми, які потрібно пройти та невпевнено розмовляє словацькою. В групі 5-6 учасників. Курс триває …… (допишемо)",
    title3: "Розмовний клуб для рівнів В1+",
    text3: "Клуб підходить для тих, хто вже говорить словацькою та знає її на хорошому рівні. Зосереджується на розмовній практиці та поповненні словникового запасу. Вартість одного заняття - 1,5 год - 7€.",
    title4: "Індивідуальні заняття",
    text4: "Підходить для будь-якого рівня, від початківців до С1. Одне заняття триває 60 хвилин. Вартість 10€.",
    title5: "Парні заняття.",
    text5: "Підходить для друзів, партнерів або незнайомих студентів, які хочуть покращити свою словацьку в парі. Заняття триває 60 хв. Вартість за двох студентів 15€ (один студент - 7.5€)",
    appl:'podať si prihlášku'
  }
});

const data = [
  {
    title: strings.title1,
    background: "#0072bb",
    text: strings.text1,
    appl: strings.appl
  },
  {
    title: strings.title2,
    background: "#ff4c3b",
    text: strings.text2,
    appl: strings.appl
  },
  {
    title: strings.title3,
    background: "#ffca18",
    text: strings.text3,
    appl: strings.appl
  },
  {
    title: strings.title4,
    background: "#44c1c1",
    text: strings.text4,
    appl: strings.appl
  },
  {
    title: strings.title5,
    background: "#29c53c",
    text: strings.text5,
    appl: strings.appl
  }
];

export default data;

  



